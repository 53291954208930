import type { NextPageContext } from 'next'
import NextError from 'next/error'
import Rollbar from 'rollbar'

function Error({ statusCode }: { statusCode: number }) {
  return <NextError statusCode={statusCode} />
}

Error.getInitialProps = ({ req, res, err }: NextPageContext) => {
  const statusCode = res?.statusCode || err?.statusCode || 404

  // Only require Rollbar and report error if we're on the server
  if (typeof window !== 'undefined' && err && statusCode !== 404) {
    const rollbar = new Rollbar({
      accessToken: process.env.ROLLBAR_SERVER_TOKEN,
      environment: process.env.NODE_ENV === 'production' ? 'production' : 'testenv',
      nodeSourceMaps: true,
    })

    rollbar.error(err, req, (rollbarError) => {
      if (rollbarError) {
        // eslint-disable-next-line no-console
        console.error('[rollbar] error occurred while reporting', rollbarError)
      } else {
        // eslint-disable-next-line no-console
        console.log('[rollbar] reported', err)
      }
    })
  }

  return { statusCode }
}

export default Error
